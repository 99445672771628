.bazis-switcher {
    .sl-form &:not(bazis-grid bazis-row:last-of-type &) {
        margin-bottom: var(--bazis-margin-3x);
    }

    app-edit-master-account &__label {
        --font-weight: 400;
        --font-size: 14px;
        --line-height: 20px;
    }

    app-modal-edit-system-conditions &,
    app-modal-edit-bank-conditions &,
    app-fee-modal-edit & {
        margin-bottom: var(--bazis-margin-6x);
        &__label {
            --font-weight: 400;
            --font-size: 13px;
            --line-height: 20px;
        }
    }
}

bazis-switcher {
    & + & {
        .bazis-switcher {
            margin-top: var(--bazis-margin-4x);
        }
    }
}
