.sl-list {
    &_easy {
        > .bazis-item + .bazis-item {
            --padding-top: var(--bazis-padding-4x);

            app-profile-licenses & {
                --padding-top: var(--bazis-padding-6x);
            }
        }

        > .bazis-item {
            bazis-tooltip & {
                --padding-top: var(--bazis-padding-3x);
            }

            .bazis-accordion_clear.bazis-block_accent & + .bazis-item {
                --padding-top: var(--bazis-padding-2x);
            }
        }
    }

    &_vtabs {
        --color: var(--bazis-text-color);
        --font-size: 13px;
        --font-weight: 400;
        --line-height: 20px;
        --padding-start: calc(var(--bazis-padding-4x) - 2px);
        --padding-end: var(--bazis-padding-5x);
        --padding-top: var(--bazis-padding-2x);
        --padding-bottom: var(--bazis-padding-2x);
        --border-width: 0 0 0 2px;
        --border-color: transparent;
        --border-style: solid;
        --border-radius: 0;

        aside & {
            --margin-start-list: calc(var(--bazis-margin-4x) * -1);
            --margin-top-list: var(--bazis-margin-2x);
        }

        aside.sl-aside_left & {
            --margin-start-list: calc(var(--bazis-margin-5x) * -1);
        }

        .bazis-item {
            text-decoration: none;
            cursor: pointer;

            &--active {
                --font-weight: 500;
                --border-color: var(--bazis-text-color);
            }
        }
    }
}

.bazis-list {
    &_short-info {
        > .bazis-item {
            .bazis-browse-default & {
                --color: var(--bazis-text-color);
            }
        }
    }
}
