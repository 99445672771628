.bazis-cckeditor .sl-contact-table {
  td {
    vertical-align: top;
    padding: 0 0 var(--bazis-padding-6x) 0;
    border: 0;

    a {
      font-weight: 500;
      font-size: var(--font-size-m);
      line-height: var(--line-height-m);
      text-decoration: none;
    }

    .bh-font-medium {
      margin-bottom: 0 0 var(--bazis-margin-1x)
    }
  }

  td:first-child {
    width: 48px;
    padding-right: 24px;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
}
