.sl-tabs-wrap {
    margin-bottom: var(--bazis-margin-4x);

    &::after {
        content: '';
        position: relative;
        top: -1px;
        z-index: -1;
        display: block;
        border-bottom: 1px solid var(--bazis-border-color);
    }
    bazis-tabs {
        --height: 36px;
        padding: 0 var(--bazis-padding-3x);
    }
}
