bazis-chat.sl-chat_full {
    width: 100%;
}

.bazis-chats {
    &__search {
        .bazis-control {
            &__field {
                &::placeholder {
                    font-size: 0;
                    color: transparent;
                }
            }
        }
    }
}

.bazis-chat {
    &__header {
        bazis-toolbar {
            --padding-start: var(--bazis-padding-6x);
            --padding-end: var(--bazis-padding-6x);
        }
    }

    &__field-wrapper--focused {
        border-color: var(--bazis-color-action);
    }

    &__control {
        bazis-button.bazis-color-primary {
            --bazis-color-base: var(--bazis-color-action) !important;
            --bazis-color-base-rgb: var(--bazis-color-action-rgb) !important;
            --bazis-color-contrast: var(--bazis-color-action-contrast) !important;
            --bazis-color-contrast-rgb: var(--bazis-color-action-contrast-rgb) !important;
            --bazis-color-shade: var(--bazis-color-action-shade) !important;
            --bazis-color-shade-grb: var(--bazis-color-action-shade-rgb) !important;
            --bazis-color-tint: var(--bazis-color-action-tint) !important;
        }
    }
}

.bazis-list_chat {
    .bazis-item {
        --background-selected: var(--bazis-color-action);
    }
}

.sl-chat-page {
    app-footer {
        display: none;
    }

    .bazis-page {
        --padding-bottom: 0;
    }

    .bazis-chats {
        height: calc(100vh - 342px);
    }
}
