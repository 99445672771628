.cdk-drag-handle {
    &,
    &:hover {
        cursor: grab;
    }

    bazis-button:hover::part(native) {
        cursor: pointer;
    }
}

bazis-button.cdk-drag-handle::part(native) {
    cursor: grab;
}

// app-rules-list-single-item.cdk-drag {
//     cursor: grabbing;

//     .cdk-drop-list & {
//         cursor: default;
//     }
// }
