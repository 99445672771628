.bazis-container,
.bazis-container_margin {
    @media (max-width: 1024px) {
        padding: 0 var(--bazis-padding-5x);
    }
}

hr {
    .bazis-block_accent & {
        --border-color: var(--bazis-border-color-dark);
    }
}
