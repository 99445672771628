// Если вы будете использовать готовую тему из базиса, то сначала импортируем переменные тему, потом остальные файлы.
// В этом случае скорректируйте файл angular.json

// Если вы будете использовать набор своих переменных, но с темой базиса, то можете импортировать файл со своими переменными в файле angular.json
// В этом случае рекомендуется импорт переменных из темы базиса закомментировать.
// @import "./src/bazis/styles/themes/sw/variables.scss";

@import "./src/bazis/styles/bazis";

// Если вы хотите построить свою тему на базе темы из базиса, вы можете скопировать устройство папок и файлов из раздела выбранной темы из базиса.
// В этом случае рекомендуется импорт самой темы из базиса закомментировать.
@import "./src/bazis/styles/themes/sw/core.scss";

// ****** //
@import "structure";
@import 'typography';

/* Layout Components of alphabet */
@import 'components/layout/aside';
@import 'components/layout/footer';
@import 'components/layout/header';
@import 'components/layout/layout';
@import 'components/layout/section';

/* Bazis components and classes, extended */
@import "base/main.scss";
@import "components/web/main.scss";
@import "components/common/main.scss";
@import "components/form/main.scss";
@import "components/table/main.scss";

/* Project Components of alphabet */
@import "components/project/main";

/* Filters Components */
@import 'components/filter/filter';
// @import 'components/filter/control.base';
@import 'components/filter/control.additional';

/* Modules */
@import 'modules/chat/chat';
@import 'modules/analytics/analytics';


/* Pages */
@import 'pages/page';
// @import 'pages/landing-page';
// @import 'pages/dummy-page';
@import 'pages/entrance-page';
@import 'pages/legal-page';
@import 'pages/list-page';
@import 'pages/main-page';
@import 'pages/profile-page';
@import 'pages/referral-page';
@import 'pages/service-page';
@import 'pages/services-page';
// @import 'pages/simple-page';
@import 'pages/analytics-page';
@import 'pages/contacts-page';

/* Blocks */
@import 'blocks/announcement';
@import 'blocks/faq';
@import 'blocks/notifications';
@import 'blocks/propositions';
