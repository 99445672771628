.bazis-page {
    --padding-bottom: 120px;

    & & {
        --padding-bottom: 0;
    }

    &-header {
        border-bottom: 1px solid var(--bazis-border-color);
        margin-bottom: var(--bazis-margin-8x);

        &.bh-sticky {
            background: var(--bazis-background);
        }

        &--scrolling.bh-sticky {
            overflow: hidden; // для обрезки after, удлинения бордера

            h1 {
                // 52 -> 22
                font-size: 20px;
                font-weight: 400;
                line-height: normal;
            }

            // TODO: проверить по новым оступам в проектах где есть скролл
            bazis-toolbar {
                --margin-bottom: 0;

                & + .bazis-list_short-info {
                    --margin-bottom-list: var(--bazis-margin-3x);
                }
            }

            // компенсация высоты 36
            &::after {
                content: '';
                display: block;
                border-top: 1px solid var(--bazis-border-color);
                height: 36px;
                margin: 0 -9999px;
            }
        }

        bazis-tabs {
            position: relative;
            bottom: -1px;
        }

        bazis-button {
            bazis-icon.bazis-icon-medium {
                margin-left: calc(var(--bazis-margin-2x) * -1);
                margin-right: calc(var(--bazis-margin-2x) * -1);
                font-size: 20px;
            }
        }

        // only agro project (пока по макету)
        .bh-line-elements {
            --gap: 40px;
        }

        .bazis-dropdown bazis-button.bazis-dropdown__trigger bazis-icon.bazis-icon-s {
            --bazis-icon-s-size: 20px;
            margin-left: calc(var(--bazis-margin-2x) * -1);
            margin-right: calc(var(--bazis-margin-2x) * -1);
        }
    }

    &__tabs {
        padding-top: var(--bazis-padding-8x);
        margin-bottom: var(--bazis-margin-8x);

        &::after {
            content: '';
            position: relative;
            top: -1px;
            z-index: -1;
            display: block;
            border-bottom: 1px solid var(--bazis-border-color);
        }
    }

    @media (max-width: 576px) {
        --padding-bottom: 88px;

        &-header {
            bazis-toolbar {
                + .bazis-list_short-info {
                    --margin-top-list: var(--bazis-margin-6x);
                    overflow-y: auto;

                    > .bazis-item {
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}
