.sl-select-telegram {
    .bazis-list_control-dd {
        --max-height: 332px;
        --margin-top-list: var(--bazis-margin-2x);
        --padding-top-list: var(--bazis-padding-4x);
        --padding-bottom-list: var(--bazis-padding-4x);
        --position-top: 0;
        position: relative;
        overflow: hidden;

        .bazis-item {
            display: flex;
            align-items: center;

            &.bazis-checkbox {
                --background-hover: transparent;
            }
        }

        form {
            margin-bottom: var(--bazis-margin-4x);

            bazis-input-default {
                width: 100%;
            }
        }
    }

    &__scroll-content {
        overflow: auto;
        max-height: 180px;
        margin-right: var(--bazis-margin-1x);
    }

    form {
        margin: 0 var(--bazis-margin-3x);
    }

    &__footer {
        margin-top: var(--bazis-margin-6x);
        border-top: 1px solid var(--bazis-border-color);
        padding: var(--bazis-padding-3x);
    }
}
