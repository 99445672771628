.sl-analytics-page {
    hr {
        --margin-top: var(--bazis-margin-6x);
        --margin-bottom: var(--bazis-margin-6x);
    }

    .bazis-grid_medium > bazis-row {
        > bazis-col {
            --bazis-grid-column-padding-bottom: var(--bazis-padding-4x);
            --bazis-grid-column-padding-top: var(--bazis-padding-4x);
        }

        &:first-child > bazis-col {
            --bazis-grid-column-padding-top: 0;
        }
        &:last-child > bazis-col {
            --bazis-grid-column-padding-bottom: 0;
        }
    }
}
