bazis-badge {
    app-selected-proposition &.bazis-color-warning,
    app-payment-bid-summary-widget &.bazis-color-warning {
        --height-medium: 20px;
        --font-size-medium: 14px;
        --margin-start: 0;
        --margin-end: 0;
        --border-radius-medium: 2px;
        color: var(--bazis-text-color);
    }

    app-selected-proposition &.bazis-color-warning {
        --font-weight: 400;
    }
}
