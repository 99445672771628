.sl-footer {
    /*
    * @prop --padding-top: верхний внутренний отступ
    * @prop --padding-bottom: нижний внутренний отступ
    * @prop --background: фон подвала
    * @prop --color: цвет текста в подвале
    * @prop --color-link: цвет ссылок в подвале
    */
    --color-link: var(--bazis-color-action);
    --padding-top: initial;
    --padding-bottom: initial;

    position: relative;
    padding-top: var(--padding-top);
    padding-bottom: var(--padding-bottom);
    background: var(--background);
    color: var(--color);

    a {
        color: var(--color-link);
    }
}

.sl-footer {
    --padding-top: 64px;
    --padding-bottom: 40px;
    --background: var(--bazis-footer-background);
    --color: var(--bazis-border-color-dark);
    --color-link: var(--bazis-color-white);
    color: var(--color-link);

    bazis-logo {
        --height: 36px;
        --width: 36px;
    }

    a {
        font-weight: 400;
        line-height: 20px;
        text-decoration: none;

        &:hover {
            color: var(--color-link);
        }
    }

    hr {
        --border-color: #374957;
    }

    &__menu {
        --bazis-grid-columns: 15;
        --bazis-grid-padding: 0;
        margin-left: 102px;
        margin-top: var(--bazis-margin-2x);
        color: var(--color);

        > bazis-row > bazis-col {
            --bazis-grid-column-padding-start: var(--bazis-padding-3x);
            --bazis-grid-column-padding-end: var(--bazis-padding-3x);
            --bazis-grid-column-padding-top: 0;
            --bazis-grid-column-padding-bottom: calc(var(--bazis-padding-6x) * 2);

            &:first-child {
                --bazis-grid-column-padding-start: 0;
            }
            &:last-child {
                --bazis-grid-column-padding-end: 0;
            }
        }

        .bazis-list {
            --margin-top-list: var(--bazis-margin-4x);
            --margin-bottom: var(--bazis-margin-3x);

            // &.sl-tech-menu {
            //     --margin-bottom: var(--bazis-margin-5x);
            // }

            .bazis-item {
                &:last-child {
                    --margin-bottom: 0;
                }
            }

            bazis-icon {
                margin-right: var(--bazis-margin-4x);
                color: var(--color);
            }
        }
    }

    &__development {
        display: flex;
        align-items: center;
        gap: var(--bazis-margin-6x);
        font-size: 11px;
        line-height: 12px;
        color: var(--color-link);

        a {
            line-height: 0;
        }

        img {
            height: 32px;
            width: auto;
        }

        span {
            width: 148px;
            flex-shrink: 0;
            opacity: .5;
        }
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .bazis-list_short-info .bazis-item {
        --color: var(--color-link);
        white-space: nowrap;
    }

    @media (max-width: 1024px) {
        &__menu {
            --bazis-grid-columns: 12;
            margin-left: 75px;

            > bazis-row > bazis-col {
                --bazis-grid-column-padding-start: 0;
                --bazis-grid-column-padding-end: var(--bazis-padding-6x);
            }
        }

        hr {
            --margin-top: 0;
        }

        .sl-footer__footer {
            flex-direction: column;
            align-items: flex-start;

            hr {
                --margin-top: var(--bazis-margin-8x);
                --margin-bottom: var(--bazis-margin-6x);
                width: 100%;
            }
        }
        .sl-footer__development {
            flex-direction: row-reverse;

            img {
                height: 20px;
            }

            span {
                margin-left: calc(var(--bazis-margin-2x) * -1);
            }
        }
    }

    @media (max-width: 576px) {
        hr {
            --margin-top: var(--bazis-margin-6x);
            --margin-bottom: var(--bazis-margin-6x);

            &.bh-hide-sm-up {
                --margin-top: var(--bazis-margin-8x);
                --margin-bottom: 40px;
            }
            &.bh-hide-xl-up {
                --margin-top: var(--bazis-margin-6x);
            }
        }

        .sl-footer__menu {
            margin-left: 0;

            > bazis-row > bazis-col {
                --bazis-grid-column-padding-end: 0;
                --bazis-grid-column-padding-bottom: var(--bazis-padding-8x);
            }
        }

        .bazis-list_short-info {
            display: block;

            > .bazis-item {
                --padding-start: 0;
                --margin-bottom: var(--bazis-margin-3x);

                &:last-child {
                    --margin-bottom: 0;
                }

                &::after {
                    display: none;
                }
            }
        }
    }
}
