.sl-form {
    &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: var(--bazis-padding-5x);
        padding-bottom: var(--bazis-padding-5x);
        margin-top: calc(var(--bazis-margin-6x) * 2);
        background: var(--bazis-background);
        border-top: 1px solid var(--bazis-border-color);
        min-height: 77px;

        &::after,
        &::before {
            display: none;
        }

        app-draft-footer & {
            margin-top: 0;
            box-shadow: 0px -15px 15px -10px rgba(var(--bazis-shadow-color-rgb), 0.4);
        }

        &__right {
            display: flex;
            align-items: center;
            gap: var(--bazis-margin-4x);
        }
    }

    &_oneline {
        display: flex;
        gap: 16px;

        &__full {
            width: 100%;
        }
    }

    @media (max-width: 576px) {
        &__footer {
            flex-direction: column-reverse;
            gap: var(--bazis-margin-4x);

            .bh-line-elements:not([slot]) {
                flex-direction: column;
                width: 100%;

                app-view-entity-footer-skeleton & > div,
                app-view-entity-footer-skeleton & bazis-skeleton {
                    width: 100%;
                }
            }
            &_finish {
                flex-direction: column;
                position: fixed;
                bottom: 0;
                left: 0;
                right: 0;
            }
            &__right {
                flex-direction: column-reverse;
                width: 100%;

                > * {
                    width: 100%;
                }
            }
            bazis-button {
                display: block;
                width: 100%;
            }

            bazis-toolbar {
                width: 100%;
                gap: var(--bazis-margin-4x);
                // flex-direction: column;

                // &::part(start),
                // &::part(end) {
                //     width: 100%;
                // }

                [slot].bh-line-elements {
                    flex-direction: column;
                }
            }
            bazis-entity-transits bazis-toolbar {
                gap: var(--bazis-margin-4x);

                &::part(start),
                &::part(end) {
                    gap: var(--bazis-margin-4x);
                }

                // [slot='end'] {
                //     display: flex;
                //     gap: var(--bazis-margin-4x);
                // }
            }
        }

        &_oneline {
            flex-direction: column;
            gap: var(--bazis-margin-2x);

            bazis-button {
                margin-top: var(--bazis-margin-2x);
            }
        }
    }
}

.bazis-datetime-control {
    .sl-control-no-margin & {
        margin-bottom: 0;
    }
}

.sl-eraser_outer {
    position: absolute;
    z-index: 1;
    right: 0;
    top: -8px;
}

.sl-loader-form {
    height: calc(100vh - 320px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.bazis-grid_form .sl-row_error {
    margin-bottom: var(--bazis-margin-6x);

    .bazis-display-error {
        @extend .bazis-error;
    }
}

.bazis-form__group-title {
    --bazis-h6-margin: 0 0 var(--bazis-margin-5x);
}

.bazis-form__group-title:not(h6) {
    margin-bottom: var(--bazis-margin-5x);
}

app-draft-bid-payment {
    .bazis-datetime-control {
        --bazis-grid-columns: 8;
    }
}
