.bazis-block {
    aside.sl-aside_left & {
        --padding-top: var(--bazis-padding-4x);
        --padding-start: var(--bazis-padding-5x);
        --padding-end: var(--bazis-padding-5x);
        --padding-bottom: var(--bazis-padding-4x);

        @media (max-width: 992px) {
            &,
            &_outline {
                --padding-start: 0;
                --padding-end: 0;
                --padding-top: 0;
                --padding-bottom: 0;
                --border-width: 0;

                bazis-button {
                    display: inline-block;
                }
            }
        }

        @media (max-width: 576px) {
            &,
            &_outline {
                bazis-button {
                    display: block;
                }
            }
        }
    }

    app-announcement-list aside & {
        --padding-top: var(--bazis-padding-6x);
        --padding-bottom: var(--bazis-padding-6x);
        --padding-start: var(--bazis-padding-6x);
        --padding-end: var(--bazis-padding-6x);
    }

    .bazis-cckeditor + &_secondary {
        // widget
        --margin-top: calc(var(--bazis-margin-3x) * 3);
    }

    &_outline {
        .sl-page_detail main & {
            --margin-bottom: 40px;
        }
    }

    &_warning {
        app-proposition-form & {
            --margin-top: calc(var(--bazis-margin-2x) * -1);
            --margin-bottom: var(--bazis-margin-6x);
            --background: rgba(var(--bazis-color-warning-rgb), 0.1);
        }
    }
}

.sl-block {
    &_faq {
        --padding-top: var(--bazis-padding-6x);
        --padding-start: var(--bazis-padding-6x);
        --padding-end: var(--bazis-padding-6x);
        --padding-bottom: var(--bazis-padding-8x);
        --border-width: 1px;
        --border-style: solid;
        --border-color: var(--bazis-border-color);
        --border-color-hover: var(--bazis-border-color-dark);

        &:last-child {
            --margin-bottom: 0;
        }
    }
    &_medium {
        --padding-top: var(--bazis-padding-5x);
        --padding-bottom: var(--bazis-padding-5x);
        --padding-start: var(--bazis-padding-5x);
        --padding-end: var(--bazis-padding-5x);
    }

    &_action {
        --margin-bottom: 0;
        --padding-top: var(--bazis-padding-3x);
        --padding-start: var(--bazis-padding-4x);
        --padding-end: var(--bazis-padding-4x);
        --padding-bottom: var(--bazis-padding-3x);
        --background: var(--bazis-color-action);
        color: var(--bazis-color-action-contrast);

        .bazis-block__content {
            display: flex;
            gap: var(--bazis-margin-3x);
            align-items: center;
            overflow: hidden;
            line-height: 20px;
        }

        bazis-button.bazis-btn_outline {
            --background: transparent;
            --background-hover: transparent;
            --background-activated: transparent;
            --color: var(--bazis-color-action-contrast);
        }

        @media screen and (max-width: 1024px) {
            .bh-flex {
                flex-direction: column;
                align-items: flex-start;
                gap: var(--bazis-margin-3x);
            }
            .bazis-block__content {
                width: 100%;
                flex-direction: row-reverse;
                justify-content: space-between;
            }
        }

        @media screen and (max-width: 576px) {
            bazis-button {
                display: block;
            }
        }
    }
}
