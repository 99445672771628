.bazis-tag {
    .sl-filter &,
    .bazis-analytics-selected__item & {
        --height: 24px;
        --border-radius: 12px;
        --padding-start: var(--bazis-padding-3x);
        margin-bottom: 0;
        line-height: 2;

        > span {
            @extend .bh-ellipsis;
            max-width: 350px;

            @media (max-width: 576px) {
                max-width: 300px;
            }

            &.sl-text_minor {
                color: rgba(var(--bazis-color-contrast-rgb), 0.7);
            }
        }

        button {
            padding: 0;
            margin-left: var(--bazis-margin-2x);
        }

        &:hover {
            background: var(--bazis-color-shade);
        }
    }

    .bazis-analytics-selected__item & {
        .bazis-text_minor {
            opacity: 0.7;
        }
    }

    .sl-announcement__tags & {
        --height: 28px;
        --border-width: 1px;
        --border-style: solid;
        --padding-start: var(--bazis-padding-3x);
        margin-bottom: 0;
        letter-spacing: 0.25px;
        cursor: pointer;

        &.bazis-color {
            --background: transparent;
            --border-color: var(--bazis-color-base);
            --color: var(--bazis-color-base);
        }
    }

    .sl-select-telegram & {
        --height: auto;
        --padding-top: var(--bazis-padding-2x);
        --padding-bottom: var(--bazis-padding-2x);
        align-items: flex-start;
    }
}
