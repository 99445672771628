.sl-widget {
    &__files {
        margin-bottom: var(--bazis-margin-5x);

        &:last-child {
            margin-bottom: 0;
        }

        .bazis-file-inline {
            min-height: 24px;
            margin-bottom: var(--bazis-margin-3x);
        }
    }
}
