.bazis-selectable-cards {
    &__item {
        &__title {
            height: 24px;
        }

        bazis-icon {
            --bazis-icon-md-size: 18px;
        }

        input:checked:disabled + & {
            background: var(--background-active);
            border-color: var(--border-color-active);
            color: var(--color-active);
            opacity: 0.5;
        }
    }
}
