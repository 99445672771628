.bazis-cckeditor {
    ul {
        list-style-type: disc;

        li {
            margin-left: var(--bazis-margin-5x);
            padding-left: var(--bazis-padding-2x);

            &::marker {
                color: var(--bazis-color-primary);
            }
        }
    }

    .sl-services-page & {
        --cckeditor-font-size: 18px;
        --cckeditor-line-height: 28px;
        --cckeditor-margin-h3: 64px 0 var(--bazis-margin-8x);
        --bazis-p-margin: 0 0 var(--bazis-margin-5x);
    }
}
