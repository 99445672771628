h1,
.bazis-h1 {
    + .sl-h1_subtitle {
        margin-top: var(--bazis-margin-3x);
        padding-bottom: var(--bazis-padding-8x);
    }

    app-draft-bid-payment & {
        @media (max-width: 1024px) {
            --bazis-h1-margin: var(--bazis-margin-8x) 0 0;
        }
    }
}

h3 {
    .bazis-block_outline + & {
        --bazis-h3-margin: 40px 0 var(--bazis-margin-6x);
    }

    .sl-page_detail main & {
        --bazis-h3-margin: 0 0 var(--bazis-margin-5x);
    }
}

h6,
.bazis-h6 {
    aside.sl-aside_right & {
        --bazis-h6-margin: 0 0 var(--bazis-margin-2x);
    }
    aside.sl-aside_right bazis-toolbar &.bazis-h6_minor {
        --bazis-h6-margin-minor: 0;
    }

    // .sl-page_edit & {
    //     --bazis-h6-margin: 0 0 var(--bazis-margin-5x);
    // }
}

p {
    .sl-grid_table &:first-child:not(.bazis-control__error p) {
        margin-top: 0;
    }
}

.sl-letter-spacing {
    &_05 {
        letter-spacing: 0.5px;
    }
    &_025 {
        letter-spacing: 0.25px;
    }
}
