.sl-button_tg {
    --bottom: 84px;
    --margin-end: 40px;

    display: flex;
    justify-content: flex-end;

    position: sticky;
    bottom: var(--bottom);
    z-index: 150;
    pointer-events: none;
    height: 0;

    margin-right: var(--margin-end);

    bazis-button {
        --height-large: 56px;
        --border-radius: 50%;
        pointer-events: all;
        width: 56px;
        margin-top: -92px;

        bazis-icon[slot=icon-only] {
            margin-right: calc(var(--bazis-margin-1x) * -1);
            margin-left: calc(var(--bazis-margin-1x) * -1);
            font-size: 24px;
        }
        @supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
            bazis-icon[slot=icon-only] {
                margin-left: unset;
                margin-right: unset;
                margin-inline-start: calc(var(--bazis-margin-1x) * -1);
                margin-inline-end: calc(var(--bazis-margin-1x) * -1);
            }
        }
    }
}
