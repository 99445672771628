.sl-services-page {
    &__description {
        --bazis-font-size-p: 24px;
        --bazis-line-height-p: 32px;
        --bazis-p-margin: calc(var(--bazis-margin-3x) * 3) 0;
    }

    &__image {
        --width: 100%;
        --height: 620px;
        --border-width: 0;
        --border-radius: 6px;
        position: relative;
        margin: 0 0 calc(var(--bazis-margin-3x) * 3);

        &::after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: rgba(182, 220, 255, 0.16);
        }
    }

    &__widget {
        --padding-top: calc(var(--bazis-margin-3x) * 3);
        --padding-start: calc(var(--bazis-margin-3x) * 3);
        --padding-end: calc(var(--bazis-margin-3x) * 3);
        --padding-bottom: calc(var(--bazis-margin-3x) * 3);
        --border-radius: 12px;

        --bazis-font-size-h2: 40px;
        --bazis-line-height-h2: 48px;
        --bazis-h2-margin: 0 0 var(--bazis-margin-6x);
        --bazis-h5-margin: 0 0 40px;

        background: var(--bazis-header-background) url('#{$path}/images/services.png') no-repeat 50%
            100%;
        background-size: contain;
        color: var(--bazis-text-color-contrast);

        h2 {
            max-width: 530px;
            font-weight: 500;
        }

        bazis-button {
            --height-large: 52px;
        }

        .bazis-h5 {
            font-weight: 400;
        }
    }

    bazis-toolbar {
        margin: calc(var(--bazis-margin-4x) * 3) 0;
    }

    main.bazis-container {
        --max-width-container: 900px;
    }

    .sl-section {
        --margin-top: 0;
        --margin-bottom: calc(var(--bazis-margin-6x) * 2);

        // 1 section
        &_banner {
            position: relative;
            background-color: var(--bazis-header-background);

            .sl-section__content {
                --padding-top: 0;
                --padding-bottom: 0;
                --padding-start: 0;
                --padding-end: 0;
                position: relative;
                z-index: 2;
                text-align: center;
                color: var(--bazis-text-color-contrast);
            }

            .bazis-breadcrumbs {
                margin-bottom: 0px;
            }

            h1 {
                --bazis-h1-margin: 40px auto var(--bazis-margin-2x);
            }

            p:not(app-top-notifications p) {
                margin: 0 0 40px;
                font-size: 18px;
                line-height: 24px;
            }

            .bazis-figure {
                --background: transparent;
                --height: 320px;
                --width: auto;
                margin: 40px auto 0;
                border: 0;

                img {
                    width: auto;
                }
            }

            @media (max-width: 1024px) {
                h1 {
                    font-size: 45px;
                    line-height: 52px;
                }
            }

            @media (max-width: 576px) {
                .sl-section__content {
                    text-align: left;
                }
                h1 {
                    font-weight: 400;
                    font-size: 28px;
                    line-height: 36px;
                }
                p {
                    margin: 0 0 var(--bazis-margin-6x);
                }

                bazis-button {
                    display: block;
                }
            }
        }

        &_services {
            --gap-slider: var(--bazis-margin-6x);

            .sl-section__content {
                display: flex;
                flex-wrap: wrap;
                gap: var(--gap-slider);
            }

            .bazis-block {
                --margin-bottom: 0;
                --padding-top: var(--bazis-padding-8x);
                --padding-bottom: 240px;
                --padding-start: var(--bazis-padding-6x);
                --padding-end: var(--bazis-padding-6x);
                --border-radius: 12px;
                // height: 100%;
                width: calc((100% - var(--gap-slider) * 2) / 2);
                position: relative;
                cursor: pointer;
                text-align: center;

                .sl-button_wrapper {
                    visibility: hidden;
                    position: absolute;
                    bottom: var(--bazis-padding-8x);
                    left: 0;
                    right: 0;
                    display: flex;
                    justify-content: center;
                }

                bazis-button {
                    --background: var(--bazis-color-action-contrast);
                    --background-hover: var(--bazis-color-action-contrast);
                    --color: var(--bazis-color-action);
                }

                &:hover {
                    --box-shadow: 0px 15px 32px -10px rgba(182, 220, 255, 0.4);

                    .sl-button_wrapper {
                        visibility: visible;
                    }
                }
            }

            h5 {
                margin-bottom: var(--bazis-margin-2x);
                font-weight: 500;
            }

            p {
                margin: 0 0 var(--bazis-margin-6x);
                color: var(--bazis-text-color-secondary);
            }

            figure {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                overflow: hidden;
                height: 240px;
                margin: 0;

                img {
                    display: block;
                    height: 100%;
                    object-fit: cover;
                }
            }

            @media screen and (max-width: 768px) {
                .bazis-block {
                    width: 100%;
                }
            }
        }
    }

    @media screen and (max-width: 1024px) {
        &__description {
            --bazis-font-size-p: 20px;
            --bazis-line-height-p: 24px;
        }
        &__image {
            --height: 600px;
        }

        &__widget {
            --bazis-font-size-h2: 28px;
            --bazis-line-height-h2: 36px;
            --bazis-h2-margin: 0 0 var(--bazis-margin-4x);

            h2 {
                max-width: 100%;
            }
        }
    }

    @media screen and (max-width: 768px) {
        &__image {
            --height: 440px;
        }
        &__widget {
            --padding-start: calc(var(--bazis-margin-1x) * 10);
            --padding-end: calc(var(--bazis-margin-1x) * 10);
            background-image: none;
        }
    }

    @media screen and (max-width: 576px) {
        &__image {
            --height: 224px;
        }
        &__widget {
            --padding-start: var(--bazis-margin-5x);
            --padding-end: var(--bazis-margin-5x);
            text-align: center;

            bazis-button {
                display: block;
            }
        }
    }
}
