#propositions {
    app-proposition-item .bh-line-elements {
        --gap: var(--bazis-margin-2x);
        justify-content: flex-end;
    }
}

app-proposition {
    @media screen and (max-width: 576px) {
        bazis-entity-transits bazis-toolbar {
            > *[slot],
            > *[slot] > div {
                width: 100%;
            }

            bazis-button {
                display: block;
            }
        }

        + bazis-button {
            display: block;
        }

        .sl-proposition__footer {
            flex-direction: column;
            width: 100%;
        }
    }
}
