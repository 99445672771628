.sl-modal {

    &_xs {
        --min-height: 401px;
        --max-height: 430px;
        --max-width: 664px;

        &.sl-modal_help {
            --max-height: 503px;
        }
    }

    // &_role {
    //     --max-width: 640px;
    //     --max-height: 599px;
    //     --padding-top-content: var(--bazis-padding-5x);
    // }

    // &_filter {
    //     --max-width: 512px;
    //     --max-height: 568px;

    //     --padding-start-header: var(--bazis-padding-6x);
    //     --padding-end-header: var(--bazis-padding-6x);

    //     --padding-top-content: var(--bazis-padding-6x);
    //     --padding-bottom-content: var(--bazis-padding-6x);
    //     --padding-start-content: var(--bazis-padding-6x);
    //     --padding-end-content: var(--bazis-padding-6x);

    //     --padding-start-footer: var(--bazis-padding-6x);
    //     --padding-end-footer: var(--bazis-padding-6x);
    //     --padding-top-footer: var(--bazis-padding-5x);
    //     --padding-bottom-footer: var(--bazis-padding-5x);

    //     .bazis-control {
    //         --margin-bottom: var(--bazis-margin-5x);
    //     }
    // }

    &_form {
        --max-width: 640px;
        --min-height: 552px;
        --height: 552px;
        --padding-bottom-content: var(--bazis-padding-6x);
        --padding-top-footer: var(--bazis-padding-5x);
        --padding-bottom-footer: var(--bazis-padding-5x);

        &_xs {
            --min-height: 384px;
            --height: 384px;
        }

        h3 {
            --bazis-h3-margin: 0;
        }

        a {
            text-decoration: none;
        }
    }
}
