.sl-filter {
    @include props-padding;
    @include padding;

    // &__base {
    //     @include props-margin;
    //     @include margin;
    //     display: flex;
    //     align-items: center;

    //     &__wrapper {
    //         display: flex;
    //         width: 100%;
    //     }

    //     filter {
    //         width: 100%;
    //     }

    //     > bazis-button {
    //         --multiple: 11;
    //         --height: 56px;
    //         --border-radius: 0 var(--bazis-border-radius) var(--bazis-border-radius) 0;
    //         font-size: 16px;
    //         text-transform: uppercase;
    //         letter-spacing: 1px;
    //         flex-shrink: 0;
    //     }
    // }

    &__additional {
        @include props-padding;
        @include padding;
    }

    // @media (max-width: 1024px) {
    //     .sl-filter__base {
    //         background: transparent;

    //         &,
    //         .sl-filter__base__wrapper {
    //             display: block;
    //         }

    //         > bazis-button {
    //             --multiple: 4;
    //             --height: 44px;
    //             --border-radius: var(--bazis-border-radius);
    //             margin-right: var(--bazis-margin-8x);
    //         }
    //     }
    // }
}

// implemented
.sl-filter {
    // --padding-top: var(--bazis-padding-4x);
    --padding-bottom: var(--bazis-padding-5x);

    // &__base {
    //     --margin-bottom: var(--bazis-margin-4x);
    //     background: var(--bazis-background);
    //     border-radius: var(--bazis-border-radius);
    // }

    &__additional {
        --bazis-grid-columns: 15;
        // --padding-top: calc(var(--bazis-padding-5x) - 5px);
    }

    .bazis-list_control-dd-wrapper {
        --padding-bottom: 0;
    }

    &-apply {
        margin-top: var(--bazis-margin-3x);
    }

    @media (max-width: 992px) {
        .bazis-control_autocomplete .bazis-list_control-dd .bazis-btn_solid.bazis-color-action {
            display: none;
        }
    }

    @media (max-width: 768px) {
        // --padding-top: 0;

        // &__base {
        //     --margin-bottom: 0;
        //     background: transparent;
        // }

        &__additional {
            --bazis-grid-columns: 5;
            // --padding-top: 0;
            --bazis-grid-column-padding-bottom: 0;
        }
    }

    @media (max-width: 576px) {
        &-apply {
            --height: 44px;
            display: block;
        }
    }
}
