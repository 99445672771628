.bazis-control {
    --border-color-focus: var(--bazis-color-action);
    --border-color-active: var(--bazis-color-action);

    .sl-form & {
        --margin-bottom: var(--bazis-margin-6x);
    }

    .sl-form_oneline &,
    .sl-section .bazis-grid_form > bazis-row:last-child &,
    .sl-grid_table &,
    .sl-row_with-error &,
    .sl-toolbar_table-header &,
    .sl-toolbar_notification &,
    .sl-widget__files &,
    .sl-control_no-margin &,
    .bazis-select-during-date-control .bazis-dropdown__body & {
        --margin-bottom: 0;
    }

    .sl-card-mobile-item &:not(.sl-card-mobile__selection .bazis-control) {
        --margin-bottom: var(--bazis-margin-4x);
    }

    @media (max-width: 576px) {
        .sl-section .bazis-grid_form > bazis-row:last-child bazis-col &,
        .sl-row_with-error & {
            --margin-bottom: var(--bazis-margin-6x);
        }
        .sl-section .bazis-grid_form > bazis-row:last-child bazis-col:last-child &,
        .sl-section .bazis-grid_form > bazis-row:last-child bazis-col.bh-next-empty & {
            --margin-bottom: 0;
        }
    }
}
