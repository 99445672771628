.sl-referral-card {
    max-width: 452px;
    height: 237px;
    border-radius: 12px;
    padding: var(--bazis-padding-6x) var(--bazis-padding-5x);
    margin-bottom: var(--bazis-margin-6x);
    background: url('/assets/images/referral-card-bg.svg') 261px 100% no-repeat,
        linear-gradient(180deg, #1e283d 0%, #141e33 100%);
    color: var(--bazis-color-white);

    h5 {
        margin-bottom: var(--bazis-margin-2x);
    }

    p {
        margin: 0 0 var(--bazis-margin-8x);
    }

    .bazis-control {
        --control-width: 100%;
        margin-bottom: var(--bazis-margin-4x);
        color: var(--bazis-text-color);
    }
}

.sl-click-statistic {
    margin-top: calc(var(--bazis-margin-8x) * 2);
    margin-bottom: var(--bazis-margin-5x);

    + .bazis-btn-group {
        margin-right: var(--bazis-margin-3x);
        margin-bottom: var(--bazis-margin-8x);
    }
}

.sl-referral-grade {
    --color-card: var(--bazis-text-color-secondary);
    --background: url('/assets/images/junior.jpg');

    &_junior {
        --color-card: var(--bazis-text-color-secondary);
        --background: var(--bazis-background-medium) url('/assets/images/junior.png');
    }
    &_middle {
        --color-card: var(--bazis-color-secondary);
        --background: #eefdfe url('/assets/images/middle.png');
    }
    &_senior {
        --color-card: var(--bazis-color-success);
        --background: #f1f9ed url('/assets/images/senior.png');
    }
    &_king {
        --color-card: var(--bazis-color-tertiary);
        --background: #f2f2fe url('/assets/images/king.png');
    }
    &_vip {
        --color-card: var(--bazis-color-warning);
        --background: var(--bazis-background-info-secondary) url('/assets/images/vip.png');
    }

    max-width: 452px;
    height: 237px;
    border-radius: 12px;
    padding: var(--bazis-padding-6x) var(--bazis-padding-5x);
    margin-bottom: var(--bazis-margin-6x);
    background: var(--background) no-repeat calc(100% + 20px) calc(100% - 4px);
    background-size: 250px 250px;

    .bazis-h4_minor {
        font-weight: 700;
    }

    p {
        margin-top: 0;
    }

    &__percent {
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
    }

    &__color {
        color: var(--color-card);
    }

    .bazis-percentage-bar {
        border-radius: 4px;

        &__part {
            border-radius: 4px;
            background: var(--color-card);
        }
    }
}

.sl-referral-client-table {
    .bazis-link_none {
        cursor: default;
    }
}
