.sl-grid {
    &_global {
        > bazis-row > bazis-col {
            --bazis-grid-column-padding-start-xl: 22px;
            --bazis-grid-column-padding-end-xl: 22px;

            --bazis-grid-column-padding-start-xs: 0;
            --bazis-grid-column-padding-end-xs: 0;
            --bazis-grid-column-padding-top: 0;
            --bazis-grid-column-padding-bottom: 0;

            .sl-form__footer & {
                --bazis-grid-column-padding-top: 0;
                --bazis-grid-column-padding-bottom: 0;
            }
        }
    }
    &_accordion {
        > bazis-row > bazis-col {
            &[sizesm='6']:nth-child(odd) {
                --bazis-grid-column-padding-start-sm: 0;

                &:last-child {
                    --bazis-grid-column-padding-end-sm: var(--bazis-padding-4x);
                }
            }
            &[sizesm='6']:nth-child(even) {
                --bazis-grid-column-padding-end-sm: 0;
            }
        }
    }
    &_accordion,
    &_accordion .bazis-grid_form {
        > bazis-row > bazis-col {
            --bazis-grid-column-padding-start-sm: var(--bazis-padding-4x);
            --bazis-grid-column-padding-end-sm: var(--bazis-padding-4x);
            --bazis-grid-column-padding-start-xl: var(--bazis-padding-4x);
            --bazis-grid-column-padding-end-xl: var(--bazis-padding-4x);
            --bazis-grid-column-padding-top: 0;
            --bazis-grid-column-padding-bottom: 0;

            --bazis-grid-column-padding-start-xs: 0;
            --bazis-grid-column-padding-end-xs: 0;
        }
    }
    &_card {
        margin: 0 calc(var(--bazis-margin-3x) / -1);

        > bazis-row > bazis-col {
            --bazis-grid-column-padding-start: var(--bazis-padding-3x);
            --bazis-grid-column-padding-end: var(--bazis-padding-3x);
            --bazis-grid-column-padding-top: 0;
            --bazis-grid-column-padding-bottom: var(--bazis-padding-6x);
        }
    }
    &_filter {
        --padding-grid: var(--bazis-padding-3x);
        margin: 0 calc(var(--padding-grid) * -1);

        > bazis-row > bazis-col {
            --bazis-grid-column-padding-start: var(--padding-grid);
            --bazis-grid-column-padding-end: var(--padding-grid);
            --bazis-grid-column-padding-top: var(--padding-grid);
            --bazis-grid-column-padding-bottom: var(--padding-grid);
        }

        > bazis-row:first-child > bazis-col {
            --bazis-grid-column-padding-top: 0;
        }
    }
    &_center {
        max-width: 800px;

        > bazis-row > bazis-col {
            --bazis-grid-column-padding-top: 0;
            --bazis-grid-column-padding-bottom: 0;
        }
    }

    &_global,
    &_accordion,
    &_center {
        > bazis-row > bazis-col {
            &:first-child {
                --bazis-grid-column-padding-start: 0;
                --bazis-grid-column-padding-start-xs: 0;
                --bazis-grid-column-padding-start-sm: 0;
                --bazis-grid-column-padding-start-xl: 0;
            }
            &:last-child {
                --bazis-grid-column-padding-end: 0;
                --bazis-grid-column-padding-end-xs: 0;
                --bazis-grid-column-padding-end-sm: 0;
                --bazis-grid-column-padding-end-xl: 0;
            }
        }
    }
}
