.sl-filter__additional {
    .bazis-control {
        --margin-bottom: 0;

        &__range {
            .bazis-control__field {
                --padding-field-end: 0;
            }

            .bazis-control__field-group__end {
                bazis-button.bazis-btn_clear {
                    --padding-start: 10px;
                    --padding-end: 10px;
                }
            }
        }
    }

    @media (max-width: 1024px) {
        .bazis-control {
            // --margin-bottom: var(--bazis-margin-4x);
        }

        // bazis-row:last-child bazis-col:last-child {
        //     .bazis-control {
        //         --margin-bottom: 0;
        //     }
        // }
    }
}
