.bazis-dropdown {
    &.sl-dropdown_action {
        --top: 28px;
        --width: 210px;
        --color: var(--bazis-text-color-secondary);
        float: right;

        .bazis-list_select {
            --border-radius-list: var(--bazis-border-radius);
            --border-width-list: 1px;
            --border-style-list: solid;
            --border-color-list: var(--bazis-background-medium);
            --padding-top-list: var(--bazis-padding-3x);
            --padding-bottom-list: var(--bazis-padding-3x);
            --box-shadow: 0px 4px 24px -10px rgba(0, 0, 0, 0.1);
            border: var(--border-width-list) var(--border-style-list) var(--border-color-list);
        }
    }

    &.sl-dropdown_notifications {
        --width: 484px;
        --padding-start: 0;
        --padding-end: 0;
        --padding-top: 0;
        --padding-bottom: 0;

        .bazis-dropdown__body {
            display: none;

            &[data-show] {
                display: block;
            }
        }

        .bazis-dropdown__trigger bazis-badge {
            --background: var(--sl-notice-header-color);
            top: 2px;
            right: -2px;
        }

        @media (max-width: 576px) {
            --width: 100%;
            --border-radius: 0;
            position: static;

            .bazis-dropdown__body {
                top: 8px !important;
            }
        }
    }

    &.sl-dropdown_partner {
        --top: 62px;
        --border-width: 0;
        --border-radius: 0;
        --width: 452px;
        --padding-start: 0;
        --padding-end: 0;
        --padding-top: 0;
        --padding-bottom: 0;

        @media screen and (max-width: 576px) {
            --top: 56px;

            .bazis-dropdown__body {
                position: fixed;
                left: 0;
                right: 0;
                width: 100%;

                .sl-referral-card {
                    width: 100%;
                    border-radius: 0;
                }
            }
        }
    }

    &.sl-dropdown_rate {
        --width: 485px;
        --padding-start: 0;
        --padding-end: 0;
        --padding-top: 0;
        --padding-bottom: 0;
        --background: var(--bazis-color-white);

        bazis-button.bazis-dropdown__trigger {
            --background: rgba(var(--bazis-color-white-rgb), 0.2);
            --background-hover: rgba(var(--bazis-color-white-rgb), 0.3);
            --background-activated: rgba(var(--bazis-color-white-rgb), 0.2);

            bazis-icon[slot='end'] {
                color: rgba(var(--bazis-color-white-rgb), 0.4);
            }
        }

        .bazis-dropdown__body {
            height: 390px;
            color: var(--bazis-text-color);
        }

        @media screen and (max-width: 576px) {
            --top: 56px;

            .bazis-dropdown__body {
                position: fixed;
                left: 0;
                right: 0;
                width: 100%;
            }
        }
    }

    .bazis-tools__button & {
        --width: 236px;
        --top: 40px;

        &__body {
            z-index: 160;
        }
    }
}
