bazis-toolbar,
.bazis-collapsible-to-more,
.bazis-list-page__tools {
    bazis-select:not(.sl-analytics-page bazis-toolbar bazis-select),
    bazis-sort {
        .bazis-control {
            max-width: 205px;
        }
    }

    bazis-select .bazis-control:not(.sl-analytics-page bazis-toolbar bazis-select .bazis-control),
    bazis-sort .bazis-control,
    bazis-select-during-date .bazis-select-during-date-control {
        --margin-bottom: 0;
        --border-width: 0;
        --background: var(--bazis-color-info-tint);
        --font-size: 14px;
        --color: var(--bazis-color-action);

        .bazis-control__field-group {
            &__end {
                button {
                    border: none;
                }
            }

            bazis-icon {
                color: var(--color);
            }

            .bazis-item--selected bazis-icon {
                color: var(--color-selected);
            }
        }
    }

    @media (max-width: 1024px) {
        // bazis-select .bazis-control,
        // bazis-sort .bazis-control,
        // bazis-select-during-date .bazis-select-during-date-control,
        // .sl-dropdown_settings-filter bazis-button.bazis-dropdown__trigger {
        //     --background: var(--bazis-background-medium);
        // }
        // .bazis-btn-group .bazis-btn-group__item > bazis-button.bazis-btn_clear {
        //     --background: var(--bazis-background-medium);
        //     --background-activated: var(--bazis-background-medium);
        // }
    }

    @media (max-width: 576px) {
        bazis-select:not(.sl-analytics-page bazis-toolbar bazis-select),
        bazis-sort {
            &,
            .bazis-control {
                width: 100%;
                max-width: 100%;
            }
        }

        .sl-btn_create {
            width: 100%;
            margin: 0;

            bazis-button {
                display: block;
            }
        }
    }
}

.sl-toolbar_table,
.bazis-collapsible-to-more,
.bazis-list-page__tools {
    bazis-input-default.sl-search-control {
        width: calc(var(--max-width-container) * 0.7);
    }

    .sl-action-button {
        --background: var(--bazis-color-info-tint);
        --color: var(--bazis-color-base);
    }

    .bazis-control:not(bazis-sort .bazis-control) {
        --height: 30px;
        --margin-bottom: 0;

        .sl-form & {
            --margin-bottom: 0;
        }

        bazis-button {
            --height: 30px;
        }
    }

    bazis-sort .bazis-control {
        --height: 32px;
    }

    @media (max-width: 1024px) {
        bazis-select .bazis-control,
        bazis-sort .bazis-control {
            max-width: 100%;
        }
    }
}

.sl-toolbar_searchable {
    bazis-select .bazis-control,
    bazis-sort .bazis-control {
        --height: 36px;
        width: var(--width-other-control);
    }
}
