app-payment-lot-status bazis-status {
    display: block;
    margin: 0 0 var(--bazis-margin-1x);

    + span {
        display: block;
        line-height: 16px;
        color: var(--bazis-text-color-secondary);
    }

    .sl-toolbar_page-header & {
        text-align: right;
    }

    @media screen and (max-width:576px) {
        .sl-toolbar_page-header & {
            text-align: left;
        }
    }
}
