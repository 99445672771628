.sl-section {
    @include props-margin;
    @include margin;

    &__content {
        @include props-padding;
        @include padding;
    }
}

.sl-section {
    --margin-top: var(--bazis-margin-8x);

    &__subtitle {
        margin-bottom: var(--bazis-margin-3x);
        text-transform: uppercase;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 1px;
        color: var(--bazis-text-color-secondary);
    }

    &:first-of-type {
        --margin-top: 0;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: var(--bazis-margin-5x);

        h3 {
            --bazis-h3-margin: 0;
        }

        @media (max-width: 576px) {
            flex-direction: column;
            align-items: flex-start;

            .bazis-status {
                --padding-start: 0;
                --margin-bottom: var(--bazis-margin-3x);
            }

            .bh-line-elements {
                display: block;
                width: 100%;
            }

            bazis-button {
                display: block;
                margin-top: var(--bazis-margin-4x);
            }
        }
    }

    app-draft-bid-payment .sl-form & {
        max-width: 884px;

        @media (max-width: 1024px) {
            max-width: 100%;
        }
    }

    @media (max-width: 576px) {
        --margin-top: var(--bazis-margin-6x);
    }
}

.sl-header-section.bazis-block {
    --margin-bottom: 44px;
    --padding-top: var(--bazis-padding-8x);
    --padding-bottom: var(--bazis-padding-6x);
    --padding-start: var(--bazis-padding-8x);
    --padding-end: var(--bazis-padding-8x);

    .bazis-block__footer {
        --margin-start: calc(var(--bazis-margin-8x)* -1);
        --margin-end: calc(var(--bazis-margin-8x)* -1);
        --padding-top: var(--bazis-padding-6x);
        --padding-start: var(--bazis-padding-8x);
        --padding-end: var(--bazis-padding-8x);
    }

    // @media (max-width: 1024px) {
    //     --margin-bottom: var(--bazis-margin-8x);
    //     --padding-top: var(--bazis-padding-6x);
    //     --padding-bottom: var(--bazis-padding-6x);
    //     --padding-start: var(--bazis-padding-5x);
    //     --padding-end: var(--bazis-padding-5x);
    // }

    // @media (max-width: 576px) {
    //     --margin-top: var(--bazis-margin-6x);
    //     --margin-bottom: var(--bazis-margin-6x);
    // }
}
