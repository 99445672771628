.bazis-select-during-date-control {
    .bazis-list_control-dd {
        --color: var(--bazis-text-color);
    }
    .bazis-dropdown__body {
        .bazis-control {
        }

        // .bazis-grid_form > bazis-row > bazis-col {
        //     --bazis-grid-column-padding-start: var(--bazis-padding-1x);
        //     --bazis-grid-column-padding-end: var(--bazis-padding-1x);
        //     --bazis-grid-column-padding-start-xs: var(--bazis-padding-1x);
        //     --bazis-grid-column-padding-end-xs: var(--bazis-padding-1x);

        //     &:first-child {
        //         --bazis-grid-column-padding-start: 0;
        //         --bazis-grid-column-padding-start-xs: 0;
        //     }
        //     &:last-child {
        //         --bazis-grid-column-padding-end: 0;
        //         --bazis-grid-column-padding-end-xs: 0;
        //     }
        // }

        // .bazis-control__field-group__end bazis-button {
        //     --padding-start: var(--bazis-padding-2x);
        //     --padding-end: var(--bazis-padding-2x);
        // }
    }

    @media screen and (min-width: 1024.98px) {
        .bazis-dropdown__body {
            --width: 491px;
        }
        .bazis-grid_form {
            float: left;
            width: 336px;

            bazis-label {
                display: none;
            }

            + bazis-button {
                display: inline-block;
                margin: 0 0 0 var(--bazis-margin-4x) !important;
            }
        }
    }
}
