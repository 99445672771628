.bazis-list-page {
    // &__head {
    //     app-referral-partners & {
    //         background: var(--bazis-background);
    //     }
    // }

    &__title {
        justify-content: space-between;
        margin-bottom: var(--bazis-margin-4x);
    }

    &__filters--collapsed {
        // display: none;
        overflow: hidden;
    }

    &__applied-filters {
        --margin-top: 0;
    }

    &__tools {
        .sl-tools__group_search {
            width: 400px;
        }
    }

    @media (max-width: 1024px) {
        &__tools {
            margin-bottom: var(--bazis-margin-4x);
        }

        &__title {
            margin-top: var(--bazis-margin-8x);

            h1 {
                margin: 0;
            }
        }
    }

    @media (max-width: 576px) {
        &__tools .bazis-tools__group {
            > bazis-button {
                display: block;
                width: 100%;
            }
        }

        & &__title {
            display: flex;

            & > .bh-flex {
                display: block;
            }
        }
        // .bazis-list-page__title {
        //     .bazis-tab-status {
        //         --margin-top: var(--bazis-margin-3x);
        //         --margin-bottom: var(--bazis-margin-4x);
        //     }
        // }

        // .bazis-list-page__body {
        //     .bazis-block_shadow {
        //         --padding-start: var(--bazis-padding-5x);
        //         --padding-end: var(--bazis-padding-5x);

        //         .bazis-list_short-info > .bazis-item {
        //             --padding-start: var(--bazis-padding-2x);
        //         }

        //         .sl-accordion_info {
        //             table,
        //             tbody,
        //             tr,
        //             td {
        //                 display: block;
        //             }

        //             td.bh-text-right {
        //                 --padding-start: 0;
        //                 text-align: left !important;
        //                 font-size: 14px;
        //                 line-height: 20px;
        //                 font-weight: 500;
        //             }

        //             tr:not(:last-child) {
        //                 margin-bottom: var(--bazis-margin-6x);
        //             }
        //         }
        //     }
        // }
    }
}
