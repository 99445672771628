.sl-referral-page {
    .sl-section {
        --margin-top: 0;

        &__content {
            --padding-start: 40px;
            --padding-end: 40px;

            h2 {
                --bazis-h2-margin: 0 0 40px;
                font-weight: 500;
                text-align: center;
            }

            @media (max-width: 1280px) {
                --padding-start: 0;
                --padding-end: 0;
            }

            @media (max-width: 1024px) {
            }

            @media (max-width: 576px) {
                h2 {
                    font-size: 24px;
                    line-height: 32px;
                }
            }
        }

        // 1 section
        &_banner {
            position: relative;
            background: var(--bazis-header-background) url('/assets/images/referral-bg.png')
                no-repeat 50% 100%;
            background-size: auto 232px;

            .sl-section__content {
                --padding-top: 0;
                --padding-bottom: 88px;
                --padding-start: 0;
                --padding-end: 0;
                position: relative;
                z-index: 2;
                text-align: center;
            }

            .bazis-breadcrumbs {
                margin-bottom: 0px;
            }

            h1 {
                --bazis-h1-margin: 0 0 var(--bazis-margin-2x);
                padding-top: 64px;
                color: var(--bazis-text-color-contrast);
            }

            p:not(app-top-notifications p) {
                margin: 0 0 var(--bazis-margin-8x);
                font-size: 18px;
                line-height: 28px;
                color: rgba(var(--bazis-text-color-contrast-rgb), 0.7);
            }

            @media (max-width: 1024px) {
                h1 {
                    font-size: 45px;
                    line-height: 52px;
                }
                background-size: contain;
            }

            @media (max-width: 576px) {
                .sl-section__content {
                    --padding-bottom: 72px;
                    text-align: left;
                }
                h1 {
                    font-weight: 400;
                    font-size: 28px;
                    line-height: 36px;
                }
                p {
                    margin: 0 0 var(--bazis-margin-6x);
                }

                bazis-button {
                    display: block;
                }
            }
        }

        // 2 section
        &_how {
            .sl-section__content {
                --padding-top: 72px;
                --padding-bottom: 72px;
            }

            bazis-grid {
                bazis-col {
                    --bazis-grid-column-padding-start: var(--bazis-padding-3x);
                    --bazis-grid-column-padding-end: var(--bazis-padding-3x);
                    --bazis-grid-column-padding-top: var(--bazis-padding-3x);
                    --bazis-grid-column-padding-bottom: var(--bazis-padding-3x);

                    &:first-child {
                        --bazis-grid-column-padding-start: 0;
                    }
                    &:last-child {
                        --bazis-grid-column-padding-end: 0;
                    }

                    .bazis-block {
                        --padding-top: var(--bazis-padding-6x);
                        --padding-bottom: var(--bazis-padding-2x);
                        --margin-bottom: 0;
                        --border-radius: 12px;
                        height: 100%;

                        h5 {
                            margin-bottom: var(--bazis-margin-3x);
                            font-weight: 500;
                        }

                        p {
                            margin: 0 0 var(--bazis-margin-2x);
                            font-size: 16px;
                            line-height: 24px;

                            &.sl-block__decor {
                                margin: 0;
                                font-weight: 700;
                                font-size: 64px;
                                line-height: 64px;
                                letter-spacing: 0.5px;
                                color: rgba(var(--bazis-text-color-rgb), 0.15);
                            }
                        }

                        &:not(.bazis-block_secondary) {
                            --background: rgba(var(--bazis-color-primary-rgb), 0.15);

                            .sl-block__decor {
                                color: rgba(var(--bazis-color-primary-rgb), 0.35);
                            }
                        }
                    }
                }

                bazis-row {
                    align-items: stretch;

                    &:first-child {
                        bazis-col {
                            --bazis-grid-column-padding-top: 0;
                        }
                    }
                    &:last-child {
                        bazis-col {
                            --bazis-grid-column-padding-bottom: 0;
                        }
                    }
                }
            }

            .sl-block_center {
                --margin-top: 48px;
                --margin-bottom: 0;
                --padding-top: 40px;
                --padding-bottom: calc(var(--bazis-padding-6x) * 2);
                --padding-start: 40px;
                --padding-end: 40px;
                --border-radius: 12px;
                --background: var(--bazis-header-background) url('/assets/images/referral-how-bg.png')
                    no-repeat 50% 100%;
                --color: var(--bazis-color-white);
                background-size: cover;
                // text-align: center;

                .bazis-figure {
                    --background: transparent;
                    --width: 80px;
                    --height: 80px;
                    border: 0;
                }

                bazis-button,
                .bazis-figure {
                    margin: auto;
                }

                h2 {
                    margin: var(--bazis-margin-8x) auto var(--bazis-margin-4x);
                }

                p {
                    max-width: 800px;
                    margin: 0 auto 32px;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 24px;
                    text-align: center;
                }
            }

            @media (max-width: 576px) {
                .sl-section__content {
                    --padding-top: 64px;
                    --padding-bottom: 64px;
                }

                h2 {
                    margin-bottom: 40px;
                    text-align: left;
                }

                bazis-grid {
                    bazis-col {
                        --bazis-grid-column-padding-start: 0;
                        --bazis-grid-column-padding-end: 0;
                        --bazis-grid-column-padding-top: 6px;
                        --bazis-grid-column-padding-bottom: 6px;
                    }
                    bazis-row {
                        &:first-child {
                            bazis-col:last-child {
                                --bazis-grid-column-padding-top: 6px;
                            }
                        }
                        &:last-child {
                            bazis-col:first-child {
                                --bazis-grid-column-padding-bottom: 6px;
                            }
                        }
                    }
                }

                .sl-block_center {
                    --margin-top: calc(var(--bazis-margin-6x) * 2);
                    --padding-top: var(--bazis-padding-7x);
                    --padding-bottom: var(--bazis-padding-6x);
                    --padding-start: var(--bazis-padding-6x);
                    --padding-end: var(--bazis-padding-6x);

                    .bazis-figure {
                        display: none;
                    }

                    h2 {
                        margin: 0 0 var(--bazis-margin-5x);
                        text-align: left;
                    }

                    p {
                        text-align: left;
                    }

                    bazis-button {
                        display: block;
                    }
                }
            }
        }

        // 3 section
        &_faq {
            .sl-section__content {
                --padding-top: 72px;
                --padding-bottom: 120px;
            }

            bazis-button.bazis-btn_large {
                margin-top: 48px;
            }

            @media (max-width: 576px) {
                .sl-section__content {
                    --padding-top: 64px;
                    --padding-bottom: 64px;
                }

                h2 {
                    text-align: left;
                }

                .bh-text-center {
                    text-align: left !important;
                }
            }
        }
    }
}
