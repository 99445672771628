dl {
    &.bazis-dl_inline {
        dd {
            bazis-file-inline + bazis-file-inline {
                display: block;
                margin-top: var(--bazis-margin-2x);
            }
        }
    }

    &.bazis-dl_dot-leaders {
        &_nowrap {
            --margin-bottom-dt: var(--bazis-margin-3x);
            --margin-bottom-dd: var(--bazis-margin-3x);
            --color-dot: rgba(var(--bazis-text-color-rgb), 0.6);
        }
    }
}
