.sl-main-page {
    --header-height: 56px;
    background-color: var(--bazis-background-medium);
    // scroll-snap-type: y mandatory;

    .sl-section {
        --margin-top: 0;
        // scroll-snap-align: start;

        &__content {
            --padding-start: 40px;
            --padding-end: 40px;

            @media (max-width: 1024px) {
                --padding-start: 0;
                --padding-end: 0;
            }

            @media (max-width: 576px) {
                // --padding-start: 0;
                // --padding-end: 0;

                h2 {
                    --bazis-font-size-h2-major: 24px;
                    --bazis-line-height-h2-major: 32px;
                }
            }

            .bazis-slider__controls {
                bazis-button {
                    --height-large: 52px;

                    bazis-icon {
                        font-size: 24px;
                    }
                }
            }

            @media (hover: none) and (pointer: coarse) {
                .bazis-slider {
                    &__controls {
                        display: none;
                    }
                    &__indicator {
                        display: flex;
                    }
                }
            }

            @media (hover: hover) and (pointer: fine) {
                .bazis-slider {
                    &__controls {
                        display: block;
                    }
                    &__indicator {
                        display: none;
                    }
                }
            }
        }

        // 1 section
        &_banner {
            position: relative;
            overflow: hidden;
            height: 516px;
            background: var(--bazis-header-background);
            margin-bottom: 104px;

            .bazis-container {
                padding-right: 0;
            }

            &__bg {
                position: absolute;
                left: 33%;
                right: 0;
                margin: 0;
                width: 67%;
                height: 100%;
                max-height: 516px;
                background: url('/assets/images/cover-banner.png') no-repeat 0 100%;
                background-size: 100% auto;
            }

            .sl-section__content {
                --padding-top: 98px;
                --padding-bottom: 98px;
                --padding-start: 0;

                position: relative;
                z-index: 1;
                width: 50%;
                height: 516px;
                // max-width: 572px;
                color: var(--bazis-text-color-contrast);
            }

            .bazis-h1 {
                --bazis-font-size-h1: 45px;
                --bazis-line-height-h1: 52px;
                --bazis-h1-margin: 0 0 var(--bazis-margin-4x);
                padding-bottom: 0;
                font-weight: 400;

                + h1 {
                    --bazis-font-size-h1: 18px;
                    --bazis-line-height-h1: 28px;
                    --bazis-h1-margin: 0 0 40px;
                    opacity: 0.7;
                }
            }

            bazis-button {
                --height-large: 52px;
                font-size: 16px;
            }

            @media (max-width: 1440px) {
                &__bg {
                    // background-position: 0 100%;
                    // background-size: contain;
                }
            }

            @media (max-width: 1120px) {
                .sl-section__content {
                    width: 572px;
                }
                &__bg {
                    left: 28.5%;
                    width: 84.5%;
                }
            }

            @media (max-width: 1024px) {
                .sl-section__content {
                    --padding-top: 124px;
                    --padding-bottom: 124px;
                }
            }

            @media (max-width: 992px) {
                background: var(--bazis-header-background);

                .sl-section__content {
                    --padding-top: 40px;
                    --padding-bottom: 80px;
                    max-width: 100%;
                }

                .bazis-h1 {
                    --bazis-font-size-h1: 28px;
                    --bazis-line-height-h1: 36px;
                }

                .bazis-figure {
                    --width: 160px;
                    --height: 160px;
                    --background: transparent;
                    margin: 0 0 var(--bazis-margin-8x);
                    border: 0;
                }
            }

            @media (max-width: 576px) {
                height: auto;

                .sl-section__content {
                    --padding-bottom: 60px;
                    width: 100%;
                    height: auto;
                }

                .bazis-container {
                    padding-right: var(--bazis-padding-5x);
                }

                bazis-button {
                    display: block;
                }

                .bazis-figure {
                    --width: 120px;
                    --height: 120px;
                }
            }
        }

        // 2 section
        &_payments {
            --gap-slider: var(--bazis-margin-6x);

            .sl-section__content {
                --padding-top: 0;
                --padding-bottom: 0;
                --padding-start: 0;
                --padding-end: 0;

                --bazis-h5-margin: 0 0 var(--bazis-margin-2x);
                --bazis-font-size-p: 16px;
                --bazis-line-height-p: 24px;
                --bazis-p-margin: 0 0 var(--bazis-margin-6x);

                overflow: hidden;
                text-align: center;

                .bazis-block {
                    --margin-bottom: 0;
                    --padding-top: var(--bazis-padding-8x);
                    --padding-bottom: 240px;
                    --padding-start: var(--bazis-padding-6x);
                    --padding-end: var(--bazis-padding-6x);
                    --border-radius: 12px;
                    // height: 100%;
                    width: calc((100% - var(--gap-slider) * 2) / 3);
                    position: relative;
                    cursor: pointer;

                    .sl-button_wrapper {
                        visibility: hidden;
                        position: absolute;
                        bottom: var(--bazis-padding-8x);
                        left: 0;
                        right: 0;
                        display: flex;
                        justify-content: center;
                    }

                    bazis-button {
                        --background: var(--bazis-color-action-contrast);
                        --background-hover: var(--bazis-color-action-contrast);
                        --color: var(--bazis-color-action);
                    }

                    &:hover {
                        --box-shadow: 0px 15px 32px -10px rgba(182, 220, 255, 0.4);
                        // box-shadow: var(--box-shadow);

                        .sl-button_wrapper {
                            visibility: visible;
                        }
                    }
                }

                h5 {
                    margin-bottom: var(--bazis-margin-2x);
                    font-weight: 500;
                }

                p {
                    margin: 0 0 var(--bazis-margin-6x);
                    color: var(--bazis-text-color-secondary);
                }

                .bazis-slider {
                    --indicator-margin-top: var(--bazis-margin-6x);

                    &__wrapper {
                        overflow: initial;
                    }

                    &__content {
                        gap: var(--gap-slider);
                    }
                }

                figure {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    overflow: hidden;
                    // width: 100%;
                    // height: 160px;
                    height: 240px;
                    // border-radius: 6px;
                    margin: 0;

                    img {
                        display: block;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }

            @media (max-width: 1024px) {
                .sl-section__content {
                    --gap-slider: var(--bazis-margin-4x);

                    .bazis-block {
                        width: calc(50% - var(--gap-slider) / 2);

                        h5 {
                            --bazis-font-size-h5: 18px;
                            --bazis-line-height-h5: 24px;
                        }
                    }
                }
            }

            @media (max-width: 576px) {
                .sl-section__content {
                    --gap-slider: 0;
                    --padding-bottom: 64px;

                    .bazis-block {
                        width: 100%;
                    }
                }
            }
        }

        // 4 section
        &_about {
            .sl-section__content {
                --padding-top: 120px;
                --padding-bottom: 120px;
                --padding-start: 0;
                --padding-end: 0;

                h2 {
                    --bazis-h2-margin-major: 0 auto var(--bazis-margin-6x);
                    text-align: center;

                    + p {
                        --bazis-font-size-p: 18px;
                        --bazis-line-height-p: 24px;
                        --bazis-p-margin: 0 0 calc(var(--bazis-margin-4x) * 3);
                    }
                }

                .bazis-list {
                    --margin-bottom-list: var(--bazis-margin-6x);
                    --background: var(--bazis-color-white);
                    --padding-top: var(--bazis-padding-6x);
                    --padding-bottom: var(--bazis-padding-6x);
                    --padding-start: var(--bazis-padding-6x);
                    --padding-end: var(--bazis-padding-6x);
                    --border-radius: 12px;

                    gap: var(--bazis-margin-6x);
                    align-items: normal;

                    .bazis-item {
                        --margin-end: 0;
                        display: flex;
                        gap: var(--bazis-margin-4x);
                        width: 100%;

                        .bazis-figure {
                            --width: 56px;
                            --height: 56px;
                            --border-width: 0;
                            --backgroung: transparent;
                            margin: 0;
                        }
                    }

                    bazis-icon {
                        flex-shrink: 0;
                        background-color: var(--bazis-background-medium);
                        padding: var(--bazis-padding-4x);
                        border-radius: 50%;
                        color: var(--bazis-color-action);
                    }

                    h5 {
                        margin: 0 0 var(--bazis-margin-2x);
                        font-weight: 500;
                    }

                    .bazis-h6 {
                        font-weight: normal;
                        color: var(--bazis-text-color-secondary);
                    }
                }

                .sl-block_swift {
                    --padding-top: var(--bazis-padding-6x);
                    --padding-start: var(--bazis-padding-8x);
                    --padding-end: var(--bazis-padding-8x);
                    --margin-bottom: 0;
                    --background: var(--bazis-color-white) url('/assets/images/swift-bg.png')
                        no-repeat 100% 50%;
                    --border-radius: 12px;

                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-size: 20px;
                    line-height: 28px;
                    background-size: cover;

                    bazis-button {
                        --height-large: 52px;
                        font-size: 16px;
                    }
                }

                @media (max-width: 1024) {
                    h5 {
                        --bazis-font-size-h5: 18px;
                        --bazis-line-height-h5: 24px;
                    }
                }

                @media (max-width: 768px) {
                    .bazis-list {
                        --margin-top-list: 40px;
                        --margin-bottom-list: var(--bazis-margin-4x);
                        gap: var(--bazis-margin-4x);
                        flex-direction: column;

                        .bazis-item {
                            justify-content: space-between;
                        }
                    }
                }

                @media (max-width: 576px) {
                    --padding-top: 64px;

                    .bazis-list {
                        .bazis-item {
                            flex-direction: column-reverse;
                            align-items: center;

                            h5,
                            p {
                                text-align: center;
                            }
                        }
                    }

                    .sl-block_swift {
                        --background: var(--bazis-color-white);
                        flex-direction: column;
                        gap: var(--bazis-margin-4x);
                        text-align: center;
                    }

                    bazis-button {
                        display: block;
                        width: 100%;
                    }
                }
            }
        }

        // 5 section
        &_news {
            position: relative;
            background: var(--bazis-background-dark);

            .sl-section__content {
                --padding-top: 120px;
                --padding-bottom: 120px;
                --padding-start: 0;
                --padding-end: 0;
                // display: flex;
                // gap: 64px;

                h2 {
                    --bazis-h2-margin: 0 0 var(--bazis-margin-8x);
                    font-weight: 500;
                }

                h5 {
                    margin-bottom: var(--bazis-margin-6x);
                    font-weight: 500;
                }

                .bazis-list {
                    --border-width: 0 0 1px;
                    --border-style: solid;
                    --border-color: var(--bazis-border-color-dark);
                    --padding-bottom: var(--bazis-padding-8x);
                    --margin-bottom: var(--bazis-margin-8x);

                    width: 100%;

                    .bazis-item {
                        display: flex;
                        justify-content: space-between;
                        gap: calc(var(--bazis-margin-3x) * 4);
                    }
                }

                .bazis-figure {
                    --width: 168px;
                    --height: 104px;
                    --border-radius: 12px;
                    margin: 0;
                    background: var(--bazis-color-info);
                    cursor: pointer;
                }

                .bazis-slider {
                    margin-bottom: var(--bazis-margin-8x);

                    &__content {
                        gap: var(--bazis-margin-6x);
                    }

                    .bazis-figure {
                        --width: 100%;
                        --height: 160px;
                        margin-bottom: var(--bazis-margin-4x);
                    }

                    .bazis-block {
                        --background: transparent;
                        --padding-top: 0;
                        --padding-bottom: 0;
                        --padding-start: 0;
                        --padding-end: 0;
                        --margin-bottom: 0;
                        --width: calc((100% - var(--bazis-margin-6x)) / 2);
                        cursor: pointer;

                        &:hover .news__title_mobile {
                            color: var(--bazis-color-primary);
                        }
                    }

                    @media (max-width: 576px) {
                        .bazis-block {
                            --width: 100%;
                        }
                    }
                }

                @media screen and (max-width: 1024px) {
                    h2 {
                        --bazis-h2-margin: 0 0 40px;
                    }

                    h5 {
                        --bazis-font-size-h5: 16px;
                        letter-spacing: 0.5px;
                    }
                }
                @media (max-width: 576px) {
                    h2 {
                        --bazis-font-size-h2: 24px;
                        --bazis-line-height-h2: 32px;
                    }
                }
            }

            .sl-news {
                &__aside {
                    flex: 0 0 432px;
                    width: 432px;

                    figure {
                        width: 384px;
                        height: 384px;
                        margin: calc(var(--bazis-margin-3x) * 4) 0 0;
                    }
                }

                &__readmore {
                    --padding-start: 0;
                    --padding-end: 0;
                    --padding-top: 0;
                    --padding-bottom: 0;

                    bazis-icon {
                        border-radius: 50%;
                        background: var(--bazis-color-action);
                        color: var(--bazis-color-action-contrast);
                        margin-left: var(--bazis-margin-3x);
                        padding: 4px;
                    }
                }

                &__date {
                    margin-bottom: var(--bazis-margin-2x);
                    color: var(--bazis-text-color-secondary);
                }

                &__allBtn {
                    --background: var(--bazis-color-white);
                    --border-color: var(--bazis-border-color-dark);
                    --color: var(--bazis-color-action);
                }

                @media screen and (min-width: 1024.98px) {
                    display: flex;
                    gap: 64px;
                }
            }
        }
    }
}
