bazis-label {
    .sl-grid_accordion & {
        --font-size: 12px;
        --line-height: 16px;
        --color: var(--bazis-text-color-secondary);
        font-weight: 400;
    }

    .sl-card-mobile-item & {
        --margin-bottom: var(--bazis-margin-2x);
        --font-size: 13px;
        --line-height: 16px;
        --color: var(--bazis-text-color-secondary);
    }

    .sl-form_oneline & {
        --line-height: 36px;
    }
}
